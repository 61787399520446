/* global console, global, Office,OfficeRuntime, self, window */
import jwtDecode from "jwt-decode";
import { validateEmailRecipients } from "./helpers/validate-email-recipients";

let mailboxItem: Office.MessageCompose;

Office.initialize = () => {
  mailboxItem = Office.context.mailbox.item;
};

// Check if the subject should be changed. If it is already changed allow send. Otherwise change it.
// <param name="event">MessageSend event passed from the calling function.</param>
function validateRecipients(onSendEvent) {
  OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true }).then((accessToken: string) => {
    const requestor = jwtDecode(accessToken)["preferred_username"];
    mailboxItem.to.getAsync({ asyncContext: onSendEvent }, (toAsyncResult) => {
      mailboxItem.cc.getAsync({ asyncContext: onSendEvent }, (ccAsyncResult) => {
        mailboxItem.bcc.getAsync({ asyncContext: onSendEvent }, (bccAsyncResult) => {
          validateEmailRecipients({
            bccs: bccAsyncResult.value.map((b) => b.emailAddress),
            ccs: ccAsyncResult.value.map((c) => c.emailAddress),
            requestor,
            tos: toAsyncResult.value.map((t) => t.emailAddress),
          }).then((response) => {
            console.log("Received recipients validation response", response);

            if (response.canSend) {
              onSendEvent.completed({ allowEvent: true });
            } else {
              mailboxItem.notificationMessages.addAsync("NoSend", {
                type: "errorMessage",
                message: `This message was not sent: ${response.message}`,
              });
              onSendEvent.completed({ allowEvent: false });
            }
          });
        });
      });
    });
  });
}

function getGlobal() {
  return typeof self !== "undefined"
    ? self
    : typeof window !== "undefined"
    ? window
    : typeof global !== "undefined"
    ? global
    : undefined;
}

const g = getGlobal() as any;

// The add-in command functions need to be available in global scope
g.validateRecipients = validateRecipients;
