import axios, { AxiosResponse } from "axios";

/* global process */

export interface ValidateEmailRecipientsRequest {
  bccs: string[];
  ccs: string[];
  requestor: string;
  tos: string[];
}

export interface ValidateEmailRecipientsResponse {
  canSend: boolean;
  message: string;
}

export function validateEmailRecipients(
  params: ValidateEmailRecipientsRequest
): Promise<ValidateEmailRecipientsResponse> {
  return axios
    .post<ValidateEmailRecipientsRequest, AxiosResponse<ValidateEmailRecipientsResponse>>(
      process.env.VALIDATE_EMAIL_RECIPIENTS_FUNCTION_ENDPOINT,
      params
    )
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}
